.under-construction {
  text-align: center;
  background: rgba(255, 255, 255, 0.6); /* Increased transparency */
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  color: black;
}

.under-construction h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.under-construction h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #ff6347;
}

.under-construction p {
  font-size: 1.2em;
  margin-bottom: 20px;
}
